import { Suspense } from "react";
import { Container } from "react-bootstrap";

export function Component() {
  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container>
          <h1 className="text-center mb-5">POLÍTICA DE PRIVACIDADE</h1>

          <div className="col-12 flex-column bg-white text-black p-5 rounded">
            <p>
              Este aviso de privacidade tem como objetivo informar como nós
              coletamos, usamos, compartilhamos e armazenamos seus dados
              pessoais no contexto da nossa promoção Hiperaniversário Carrefour,
              conforme exigido pela Lei Geral de Proteção de Dados (LGPD).
            </p>

            <strong>Que informações coletamos?</strong>

            <p>Coletamos as seguintes informações pessoais sobre você:</p>

            <ul>
              <li>
                Informações de cadastro: nome completo; CPF; endereço; data de
                nascimento, endereço de e-mail e número de telefone/celular;
              </li>
              <li>
                Informações de transação: valor de compra, número da nota
                fiscal.
              </li>
            </ul>

            <strong>Como coletamos suas informações?</strong>

            <p>
              Os dados pessoais são coletados, principalmente, da seguinte
              forma:
            </p>

            <p>
              Dados fornecidos diretamente pelo titular – o titular deverá
              fornecer os dados mencionados no tópico anterior para efetivação
              do cadastro e geração do número da sorte utilizado para participar
              da promoção.
            </p>

            <strong>Como usamos suas informações</strong>

            <p>Usamos suas informações pessoais para:</p>

            <ul>
              <li>
                Gerar um número da sorte, permitindo sua participação na
                promoção Hiperaniversário Carrefour;
              </li>
              <li>
                Entrar em contato com você para a entrega do prêmio e lhe enviar
                o cartão presente, caso você esteja entre os ganhadores;
              </li>
              <li>
                Enviar comunicações relacionadas ao serviço, como atualizações e
                ofertas das empresas que compõem o Grupo Carrefour Brasil, caso
                haja o seu consentimento;
              </li>
              <li>Cumprir obrigações legais, regulatórias ou contratuais.</li>
            </ul>

            <strong>
              Podemos compartilhar suas informações pessoais com terceiros nas
              seguintes situações:
            </strong>

            <ul>
              <li>
                Entre empresas do Grupo Carrefour para enviar comunicações
                relacionadas aos serviços, como atualizações e ofertas, caso
                haja o seu consentimento;
              </li>
              <li>
                Com nossos parceiros de negócios para gestão e efetivação do
                sorteio;
              </li>
              <li>
                Com autoridades governamentais, em resposta a uma solicitação
                legal.
              </li>
            </ul>

            <strong>Manutenção dos dados</strong>

            <p>
              Manteremos seus dados pessoais pelo tempo que for necessário para
              cumprir as finalidades acima descritas, a menos que um período
              maior de retenção seja exigido ou permitido por lei, ou que seja
              necessário para cumprir obrigações legais, resguardar nossos
              direitos ou cumprir acordos judiciais/extrajudiciais.
            </p>

            <strong>Segurança de suas informações</strong>

            <p>
              Possuímos sólida Política de Segurança da Informação, a qual é
              regularmente revisitada e atualizada de forma consistente com as
              melhores práticas.
            </p>

            <p>
              Utilizamos e adotamos processos, técnicas, mecanismos e
              procedimentos de segurança e prevenção adequados e apropriados
              para assegurar a devida proteção de suas informações a fim de
              garantir a integridade, confidencialidade, autenticidade e
              disponibilidade dos dados Pessoais.
            </p>

            <strong>
              Quais são os seus direitos em relação aos seus dados pessoais?
            </strong>

            <p>De acordo com a LGPD, você tem o direito de:</p>

            <ul>
              <li>
                (i) solicitar a confirmação da existência do tratamento de dados
                pessoais;
              </li>
              <li>(ii) acesso aos dados;</li>
              <li>
                (iii) correção de dados incompletos, inexatos ou desatualizados;
              </li>
              <li>(iv) anonimização;</li>
              <li>
                (v) bloqueio ou eliminação de dados desnecessários, excessivos
                ou tratados em desconformidade com a legislação vigente;
              </li>
              <li>
                (vi) a portabilidade dos dados para outro fornecedor de serviços
                ou produtos;
              </li>
              <li>
                (vii) informações a respeito das entidades públicas ou privadas
                com quem compartilhamos os seus dados.
              </li>
            </ul>

            <strong>Como exercer seus direitos?</strong>

            <p>
              O nosso Encarregado pelo Tratamento de Dados Pessoais (DPO), Renã
              Melo Santos, e sua equipe de Privacidade terão o prazer em
              atendê-lo através do formulário disponível aqui.
            </p>

            <p>
              Através do formulário, você poderá exercer seus direitos de
              titular e realizar solicitações tais como: quais informações
              coletamos e processamos sobre você e quais razões e propósitos
              para esses processamentos.
            </p>

            <strong>Alterações neste Aviso de Privacidade:</strong>

            <p>
              Essa Política de Privacidade pode passar por atualizações. Desta
              forma, recomendamos visitar periodicamente esta página para que
              você tenha conhecimento sobre as modificações. Caso sejam feitas
              alterações relevantes que ensejem novas autorizações suas,
              publicaremos uma nova política de privacidade.
            </p>

            <p>
              É fundamental que o usuário se certifique de que lerá atentamente
              qualquer comunicação encaminhada quando relacionada às alterações
              da Política de Privacidade. Data de modificação da Política de
              Privacidade: 14/08/2023
            </p>
          </div>
        </Container>
      </section>
    </Suspense>
  );
}
